"use client";

import LanguageSwitchButton from "./language-switch-button";
// import RoundButton from "@hits/hits-ui/button/round.button";
import {
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
} from "@hits/hits-ui/dropdown/round.dropdown";
import { cn } from "@hits/hits-ui/utils/classname";
import clsx from "clsx";
import { useTranslations } from "next-intl";
// import { getTranslations } from "next-intl/server";
import { useState } from "react";
import { MdOutlineLanguage } from "react-icons/md";

export default function LanguageSelectDropdown({
  locale,
  className,
  sideOffset,
}: {
  locale: string;
  className?: string;
  sideOffset?: number;
}) {
  const t = useTranslations("LAB_SPACE.LangDropdown");
  // const t = await getTranslations({ locale });
  // const selectText =
  //   locale === "ko"
  //     ? t("LAB_SPACE.LangDropdown.selectKO")
  //     : t("LAB_SPACE.LangDropdown.selectEN");

  const [isHover, setIsHover] = useState(false);

  return (
    <DropdownRoot modal={false}>
      <div
        className="flex h-16 items-center"
        onMouseLeave={() => setIsHover(false)}
        onMouseOver={() => setIsHover(true)}
      >
        <DropdownTrigger
          aria-label="lang-trigger"
          className={cn("text-gray-c-300 focus:outline-none", className)}
          data-cy="LanguageSelectDropdown-trigger"
          id="lang-trigger"
        >
          <MdOutlineLanguage size={20} />
        </DropdownTrigger>
        <DropdownContent
          className="text-gray-c-700 text-sm"
          forceMount={isHover || undefined}
          sideOffset={4}
        >
          <DropdownItem
            asChild
            className={clsx(
              locale === "ko" &&
                "cursor-auto bg-blue-100 hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-100",
              "w-full",
            )}
          >
            <LanguageSwitchButton locale="ko">
              {t("selectKO")} (KO)
            </LanguageSwitchButton>
          </DropdownItem>
          <DropdownItem
            asChild
            className={clsx(
              locale === "en" &&
                "cursor-auto bg-blue-100 hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-100",
              "w-full",
            )}
          >
            <LanguageSwitchButton locale="en">
              {t("selectEN")} (EN)
            </LanguageSwitchButton>
          </DropdownItem>
        </DropdownContent>
      </div>
    </DropdownRoot>
  );
}
