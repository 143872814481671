globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"un7r7rkmDqK_mh7SCEq8p"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_RELEASE =
  process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE;
const SENTRY_DIST =
  process.env.SENTRY_DIST || process.env.NEXT_PUBLIC_SENTRY_DIST;
Sentry.init({
  release: SENTRY_RELEASE,
  dist: SENTRY_DIST,
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  transport: Sentry.makeFetchTransport,
  stackParser: Sentry.defaultStackParser,
  environment: process.env.APP_ENV,
  debug: false,
  beforeSend: (event) => {
    if (event.message?.startsWith("ResizeObserver loop")) {
      return null;
    }

    return event;
  },
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
    Sentry.breadcrumbsIntegration(),
    Sentry.globalHandlersIntegration(),
    Sentry.httpContextIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.functionToStringIntegration(),
    Sentry.rewriteFramesIntegration(),
  ],
});
