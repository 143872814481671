import Spinner from "../spinner/spinner";
import { type VariantProps, cva } from "class-variance-authority";
import type { ComponentProps, PropsWithoutRef, ReactNode } from "react";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type RoundButtonVariants = VariantProps<typeof RoundButtonVariants>;
export type RoundButtonType = RoundButtonVariants &
  PropsWithoutRef<ComponentProps<"button">> & {
    loading?: boolean;
    loader?: ReactNode;
    iconComponent?: ReactNode;
    iconPosition?: "left" | "right";
    rounded?: boolean;
  };

const RoundButtonVariants = cva(
  [
    "disabled:cursor-not-allowed",
    "flex items-center gap-1 justify-center group",
    "rounded-md",
    "w-fit",
  ],
  {
    variants: {
      size: {
        l: "h-[44px] button1 px-4",
        m: "h-[38px] button2 px-4",
        s: "h-[36px] button2 px-3.5",
        xs: "h-[34px] button3 px-3",
        ghost: "",
      },
      loading: {
        true: "cursor-wait",
      },
      disabled: {
        true: "",
        false: "",
      },
      iconPosition: {
        left: "",
        right: "",
      },
      rounded: {
        true: "rounded-[20px]",
      },
      color: {
        blue: [
          "text-white bg-blue-600",
          "enabled:hover:bg-blue-700",
          "enabled:active:bg-blue-800",
          "disabled:bg-blue-300",
        ],
        skyblue: [
          "text-blue-700 bg-blue-100",
          "enabled:hover:bg-blue-200",
          "enabled:active:text-white enabled:active:bg-blue-500",
          "disabled:text-blue-400 disabled:bg-blue-000",
        ],
        "outline-skyblue": [
          "text-blue-700 bg-white border border-blue-600",
          "enabled:hover:bg-blue-000",
          "enabled:active:bg-blue-100",
          "disabled:text-blue-400 disabled:border-blue-400",
        ],
        gray: [
          "text-gray-c-700 bg-gray-bg-c-200",
          "enabled:hover:bg-gray-bg-c-300",
          "enabled:active:bg-gray-bg-c-400",
          "disabled:text-gray-c-400",
        ],
        "outline-gray": [
          "text-gray-c-700 bg-white border border-gray-c-200",
          "enabled:hover:bg-gray-bg-c-200",
          "enabled:active:bg-gray-bg-c-300",
          "disabled:text-gray-c-400",
        ],
        green: [
          "text-teal-800 bg-teal-100",
          "enabled:hover:bg-teal-200",
          "enabled:active:bg-teal-600 enabled:active:text-white",
          "disabled:bg-teal-000",
        ],
        red: [
          "text-white bg-red-600",
          "enabled:hover:bg-red-700",
          "enabled:active:bg-red-800",
          "disabled:bg-red-300",
        ],
        white: [
          "text-gray-c-700 hover:text-[#0c71e9] active:text-[#0c71e9] bg-white",
          "enabled:hover:bg-white",
          "enabled:active:bg-white",
          "disabled:text-gray-c-400",
        ],
        ghost: "",
      },
    },
    compoundVariants: [
      { color: "blue", loading: true, className: "!bg-blue-600" },
      {
        color: "skyblue",
        loading: true,
        className: "!bg-blue-100 !text-blue-700",
      },
      { color: "outline-skyblue", loading: true, className: "!bg-white" },
      { color: "gray", loading: true, className: "!bg-gray-bg-c-200" },
      { color: "outline-gray", loading: true, className: "!bg-white" },
      { color: "green", loading: true, className: "!bg-teal-100" }, // todo bg-teal-600
      { color: "red", loading: true, className: "!bg-red-600" },
      { color: "white", loading: true, className: "!bg-white" },
      { iconPosition: "left", size: "l", className: "!pl-3.5 !pr-4" },
      { iconPosition: "left", size: "m", className: "!pl-3 !pr-3.5" },
      { iconPosition: "left", size: "s", className: "!pl-2.5 !pr-3" },
      { iconPosition: "left", size: "xs", className: "!pl-2 !pr-2.5" },
      { iconPosition: "right", size: "l", className: "!pl-4 !pr-3.5" },
      { iconPosition: "right", size: "m", className: "!pl-3.5 !pr-3" },
      { iconPosition: "right", size: "s", className: "!pl-3 !pr-2.5" },
      { iconPosition: "right", size: "xs", className: "!pl-2.5 !pr-2" },
    ],
  },
);

export const RoundButtonCss = (
  props: Parameters<typeof RoundButtonVariants>[number],
) => twMerge(RoundButtonVariants(props));

const RoundButton = forwardRef<HTMLButtonElement, RoundButtonType>(
  (
    {
      children,
      className,
      size = "m",
      color = "blue",
      loading,
      disabled,
      iconPosition,
      iconComponent,
      rounded,
      loader,
      ...props
    },
    ref,
  ) => {
    const loaderComponent = loader || <Spinner color={color} size={size} />;

    return (
      <button
        className={RoundButtonCss({
          className,
          color,
          size,
          loading,
          disabled,
          iconPosition,
          rounded,
        })}
        disabled={disabled || loading}
        ref={ref}
        type="submit"
        {...props}
      >
        {!loading && iconPosition === "left" && iconComponent}
        {loading ? loaderComponent : children}
        {!loading && iconPosition === "right" && iconComponent}
      </button>
    );
  },
);
RoundButton.displayName = "RoundButton";

export default RoundButton;
