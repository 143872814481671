"use client";

import RoundButton from "@hits/hits-ui/button/round.button";
import ConfirmDialog from "@hits/hits-ui/dialog/confirm.dialog";
import { captureException } from "@sentry/nextjs";
import useChannelTalk from "@shared/lib/channel-talk/use-channel-talk";
import { createStore, useAtom, useAtomValue } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { useTranslations } from "next-intl";
import { type ReactNode, useMemo } from "react";

interface GlobalConfirmModalState {
  open: boolean;
  title?: ReactNode;
  contents: ReactNode;
  hideCloseButton?: boolean;
  confirmButtonText?: string;
  closeButtonText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ModalAtom = atomWithReset<GlobalConfirmModalState>({
  open: false,
  title: null,
  contents: null,
});
const atomStore = createStore();
const atomOptions = { store: atomStore };

export function useGlobalConfirmModal() {
  const [modalState, setModalState] = useAtom(ModalAtom, atomOptions);
  const resetState = useResetAtom(ModalAtom, atomOptions);

  return useMemo(
    () => ({ modalState, setModalState, resetState }),
    [modalState, setModalState, resetState],
  );
}

export default function GlobalConfirmModal() {
  const modalData = useAtomValue(ModalAtom, atomOptions);
  const modalReset = useResetAtom(ModalAtom, atomOptions);
  const t = useTranslations("PROJECT.Common.Modal");

  return (
    <ConfirmDialog.Root
      open={modalData.open}
      onOpenChange={() => {
        modalReset();
        modalData.onClose?.();
      }}
    >
      <ConfirmDialog.Content className="z-[100000] flex min-w-[432px] flex-col gap-10">
        {modalData.title && (
          <ConfirmDialog.Title>{modalData.title}</ConfirmDialog.Title>
        )}
        <div className="text-gray-c-700 whitespace-pre-line text-center leading-[140%]">
          {modalData.contents}
        </div>
        <div className="flex gap-4">
          {!modalData.hideCloseButton && (
            <ConfirmDialog.Close asChild onClick={modalData.onCancel}>
              <RoundButton className="flex-1" color="outline-gray" size="l">
                {modalData.closeButtonText || t("deleteModalCancel")}
              </RoundButton>
            </ConfirmDialog.Close>
          )}
          <ConfirmDialog.Close asChild>
            <RoundButton
              className="flex-1"
              color="blue"
              size="l"
              onClick={modalData.onConfirm}
            >
              {modalData.confirmButtonText || t("deleteModalConfirm")}
            </RoundButton>
          </ConfirmDialog.Close>
        </div>
      </ConfirmDialog.Content>
    </ConfirmDialog.Root>
  );
}

export function useSetContactAdminModal() {
  const t = useTranslations("PROJECT.Common.Modal");
  const { setModalState } = useGlobalConfirmModal();
  const { openChannelTalk } = useChannelTalk();

  const openContactAdminModal = ({
    title,
    requestId,
  }: {
    title?: string;
    requestId: string;
  }) => {
    // requestId가 존재하는 네트워크 에러일 경우에만 열림
    if (requestId !== "error") {
      setModalState({
        open: true,
        title: title ?? t("error.title"),
        contents: t.rich("serverErrorContent", {
          br: () => <br />,
          strong: (children: ReactNode) => (
            <strong className="text-blue-600">{children}</strong>
          ),
        }),
        confirmButtonText: t("contactAdmin"),
        onConfirm: () => {
          openChannelTalk(
            undefined,
            `오류코드 : ${requestId} - 이 메시지를 지우지 마시고 문의해주세요`,
          );
          captureException(`오류코드 : ${requestId}`);
        },
      });
    }
  };

  return { openContactAdminModal };
}
