import * as Dialog from "@radix-ui/react-dialog";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { MdClose } from "react-icons/md";
import { twMerge } from "tailwind-merge";

export const ConfirmDialogRoot = Dialog.Root;
export const ConfirmDialogTrigger = Dialog.Trigger;
export const ConfirmDialogClose = Dialog.Close;
export const ConfirmDialogTitle = forwardRef<
  HTMLDivElement,
  Dialog.DialogTitleProps & { CloseIcon?: ReactNode; withClose?: boolean }
>(
  (
    {
      children,
      className,
      CloseIcon = <MdClose className="text-gray-c-400 h-7 w-7" />,
      withClose = true,
      ...props
    },
    ref,
  ) => {
    return (
      <Dialog.Title
        {...props}
        className={twMerge(
          "flex items-center justify-between",
          "text-gray-c-800 subheading2-semibold",
          className,
        )}
        ref={ref}
      >
        {children}
        {withClose && <Dialog.Close>{CloseIcon}</Dialog.Close>}
      </Dialog.Title>
    );
  },
);
ConfirmDialogTitle.displayName = "ConfirmDialogTitle";

export const ConfirmDialogContent = forwardRef<
  HTMLDivElement,
  Dialog.DialogContentProps & { overlayClassName?: string }
>(({ children, className, overlayClassName, ...props }, ref) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className={twMerge("fixed", overlayClassName)} />
      <Dialog.Content
        {...props}
        className={twMerge(
          "shadow-dialog fixed left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white p-6",
          className,
        )}
        ref={ref}
      >
        <Dialog.Description className="hidden" />
        {children}
      </Dialog.Content>
    </Dialog.Portal>
  );
});
ConfirmDialogContent.displayName = "ConfirmDialogContent";

const ConfirmDialog = {
  Root: ConfirmDialogRoot,
  Trigger: ConfirmDialogTrigger,
  Close: ConfirmDialogClose,
  Content: ConfirmDialogContent,
  Title: ConfirmDialogTitle,
  Description: Dialog.Description,
};
export default ConfirmDialog;
