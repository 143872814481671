"use client";

import Accordion from "@hits/hits-ui/accordion/accordion";
import { cn } from "@hits/hits-ui/utils/classname";
import LanguageSwitchButton from "@shared/ui/language/language-switch-button";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { ReactNode } from "react";
import {
  MdKeyboardArrowDown,
  MdOpenInNew, // MdOpenInNew,
  MdOutlineCheck,
} from "react-icons/md";

function AccordionContent({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <Accordion.Content
      className={cn(
        "tablet_768:px-10 w-screen overflow-hidden rounded-none bg-[#11141E] px-5",
        className,
      )}
    >
      {children}
    </Accordion.Content>
  );
}

const linkClassNameStyles =
  "flex h-12 items-center rounded-none px-5 tablet_768:px-10 hover:bg-blue-400/20 hover:text-blue-300";

function ArrowOpenerIcon() {
  return (
    <MdKeyboardArrowDown className="mr-2 h-6 w-6 fill-white transition group-hover:fill-blue-300 group-aria-expanded:rotate-180" />
  );
}

// TODO : Page전환시 Close해야함
export default function HamburgerMenuAccordion({ locale }: { locale: string }) {
  const t = useTranslations();
  return (
    <Accordion.Root type="multiple">
      <Accordion.Item value="1">
        <Accordion.Trigger className="tablet_768:px-10 group flex h-12 w-full items-center rounded-none px-5 transition-colors hover:bg-blue-400/20 hover:text-blue-300">
          <div className="flex h-full w-full items-center justify-between border-b-2 group-data-[state=open]:border-b-0 group-data-[state=closed]:border-b-white/10">
            <span>{t("HOME.GNB.support")}</span>
            <ArrowOpenerIcon />
          </div>
        </Accordion.Trigger>

        <AccordionContent>
          <div className="flex flex-col bg-[#1D222C]">
            <Link
              className={cn(linkClassNameStyles)}
              href="https://docs.hyperlab.hits.ai/docs/"
              target="_blank"
            >
              <span>{t("HOME.GNB.docs")}</span>
              <MdOpenInNew className="ml-[10px] h-5 w-5 stroke-white" />
            </Link>
            <Link
              className={cn(linkClassNameStyles)}
              href={`https://docs.hyperlab.hits.ai/hyper-lab-release-note${locale === "en" ? "-en" : ""}/`}
              target="_blank"
            >
              <span>{t("HOME.GNB.releaseNote")}</span>
              <MdOpenInNew className="ml-[10px] h-5 w-5 stroke-white" />
            </Link>
          </div>
        </AccordionContent>
      </Accordion.Item>

      <Accordion.Item value="2">
        <Accordion.Trigger className="tablet_768:px-10 group flex h-12 w-full items-center rounded-none px-5 hover:bg-blue-400/20 hover:text-blue-300">
          <div className="flex h-full w-full items-center justify-between border-b-2 group-data-[state=open]:border-b-0 group-data-[state=closed]:border-b-white/10">
            <span>{t("HOME.GNB.language")}</span>
            <ArrowOpenerIcon />
          </div>
        </Accordion.Trigger>

        <AccordionContent>
          <div className="bg-[#1D222C]">
            <LanguageSwitchButton
              className={cn(
                locale === "ko" ? "text-blue-300" : "",
                linkClassNameStyles,
                "w-full",
              )}
              locale="ko"
            >
              <span> {t("HOME.GNB.korean")} (KO)</span>
              {locale === "ko" && (
                <MdOutlineCheck className="ml-[10px] h-4 w-4 fill-blue-300" />
              )}
            </LanguageSwitchButton>

            <LanguageSwitchButton
              className={cn(
                locale === "en" ? "text-blue-300" : "",
                linkClassNameStyles,
                "w-full border-b-2 border-b-white/10",
              )}
              locale="en"
            >
              <span>{t("HOME.GNB.english")} (EN)</span>
              {locale === "en" && (
                <MdOutlineCheck className="ml-[10px] h-4 w-4 fill-blue-300" />
              )}
            </LanguageSwitchButton>
          </div>
        </AccordionContent>
      </Accordion.Item>
    </Accordion.Root>
  );
}
