"use client";

import LocaleSelector from "./locale-selector";
import { cn } from "@hits/hits-ui/utils/classname";
import LogoIcon from "@shared/ui/icons/LogoIcon";
import Link from "next/link";

interface Props {
  locale: string;
  className?: string;
}
export default function LocaleGNB({ locale, className }: Props) {
  return (
    <header className={cn("bg-[#fdfeff]", "sticky left-0 top-0 z-20")}>
      <div className="width-before-scroll-bar smmb:px-5 flex h-16 flex-shrink-0 items-center justify-between px-10">
        <Link className="relative" href={`/${locale}`}>
          <LogoIcon className="fill-c-gray/900 h-6" />
        </Link>

        <LocaleSelector locale={locale} sideOffset={20} />
      </div>
    </header>
  );
}
