"use client";

import channelService from "./channel-talk-service";
import { useGlobalUser } from "@hooks/global/useGlobalUser";
import { useLocale } from "next-intl";
import { useCallback, useRef } from "react";

export default function useChannelTalk() {
  const _channelTalk = useRef(channelService);
  const channelTalk = _channelTalk.current;
  const user = useGlobalUser((state) => state);
  const locale = useLocale();

  const bootChannelTalk = useCallback(() => {
    if (channelTalk.isBooted) {
      return;
    }
    channelTalk.boot({
      pluginKey: process.env.NEXT_PUBLIC_CHANNEL_TALK_KEY!,
      memberId: user.user_id,
      profile: {
        email: user.email,
        name: user.name,
      },
      language: locale,
      zIndex: 100001,
    });
  }, [channelTalk, locale, user]);

  const openChannelTalk = useCallback(
    (chatId?: string | number, message?: string) => {
      if (!channelTalk.isBooted) {
        bootChannelTalk();
      }
      channelTalk.openChat(chatId, message);
    },
    [channelTalk, bootChannelTalk],
  );

  const closeChannelTalk = useCallback(() => {
    channelTalk.shutdown();
  }, [channelTalk]);

  return { channelTalk, openChannelTalk, closeChannelTalk, bootChannelTalk };
}
